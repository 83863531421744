<template>
  <auth-master-layout>
    <b-card-text class="mb-2"> Forgot Password</b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group label="Email" label-for="login-email">
          <validation-provider
            #default="{ errors }"
            name="email "
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="payload.email"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button
          type="submit"
          variant="primary"
          block
          @click="onSubmit"
          :disabled="loading"
        >
          Send Reset Password Link
        </b-button>
      </b-form>
    </validation-observer>

    <b-card-text class="text-center mt-2">
      <b-link to="/login">
        <span>&nbsp;Sign in</span>
      </b-link>
    </b-card-text>
  </auth-master-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
} from "bootstrap-vue";
import { email, required } from "@validations";
import store from "@/store";
import AuthMasterLayout from "@/views/auth/AuthMasterLayout";

export default {
  components: {
    AuthMasterLayout,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        email: "",
      },
      required,
      email,
    };
  },
  computed: {
    loading() {
      return store.getters["auth/isAuthLoading"];
    },
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.loginValidation.validate();
      if (success) {
        this.$store.dispatch("auth/forgotPassword", this.payload);
      }
    },
  },
};
</script>

<style lang="scss"></style>
